const makePagedClientRequest = async (CommandCtor, client, input, ...args) => {
    return await client.send(new CommandCtor(input), ...args);
};
export function createPaginator(ClientCtor, CommandCtor, inputTokenName, outputTokenName, pageSizeTokenName) {
    return async function* paginateOperation(config, input, ...additionalArguments) {
        let token = config.startingToken || undefined;
        let hasNext = true;
        let page;
        while (hasNext) {
            input[inputTokenName] = token;
            if (pageSizeTokenName) {
                input[pageSizeTokenName] = input[pageSizeTokenName] ?? config.pageSize;
            }
            if (config.client instanceof ClientCtor) {
                page = await makePagedClientRequest(CommandCtor, config.client, input, ...additionalArguments);
            }
            else {
                throw new Error(`Invalid client, expected instance of ${ClientCtor.name}`);
            }
            yield page;
            const prevToken = token;
            token = page[outputTokenName];
            hasNext = !!(token && (!config.stopOnSameToken || token !== prevToken));
        }
        return undefined;
    };
}
