import { S3ExpressIdentityProviderImpl } from "./s3-express";
export const resolveS3Config = (input, { session, }) => {
    const [s3ClientProvider, CreateSessionCommandCtor] = session;
    return {
        ...input,
        forcePathStyle: input.forcePathStyle ?? false,
        useAccelerateEndpoint: input.useAccelerateEndpoint ?? false,
        disableMultiregionAccessPoints: input.disableMultiregionAccessPoints ?? false,
        followRegionRedirects: input.followRegionRedirects ?? false,
        s3ExpressIdentityProvider: input.s3ExpressIdentityProvider ??
            new S3ExpressIdentityProviderImpl(async (key) => s3ClientProvider().send(new CreateSessionCommandCtor({
                Bucket: key,
                SessionMode: "ReadWrite",
            }))),
    };
};
